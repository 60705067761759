<template>
    <div class="mt-5">

        <el-row type="flex" justify="space-between">

            <el-col :span="6">
                <el-input v-model="search" placeholder="Buscar Campañas" clearable suffix-icon="el-icon-search"/>
            </el-col>

            <el-col :span="6" class="d-flex justify-content-end">
                <router-link :to="{ name: 'campaigns-new'}">
                    <el-button type="success">
                        Nueva Campaña
                    </el-button>
                </router-link>
            </el-col>

        </el-row>

        <el-row class="mt-4 px-5 py-2 bg-white border border-gray rounded">
            <el-col>
                <el-table
                    class="table m-0"
                    ref="campaignTable"
                    @row-click="goToCampaignDetail"
                    :row-class-name="'cursor-pointer'"
                    emptyText="Completa tu primera campaña"
                    :data="campaigns.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))">

                    <el-table-column
                        label="Disponibilidad"
                        width="150">

                        <template slot-scope="data">
                            <el-switch
                                :value="data.row.status"
                                active-value="PUBLISHED"
                                @change="changeCampaignStatus(data.row.uuid, $event)"/>
                        </template>
                    </el-table-column>

                    <el-table-column label="Nombre" width="300">
                        <template slot-scope="data">
                            <span class="table-cell-name">{{data.row.name}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Tipo de Campaña"
                        :filters="typesFilter"
                        :filter-method="filterByType">
                        <template slot-scope="data">
                            {{parseType(data.row.type)}}
                        </template>
                    </el-table-column>

                    <el-table-column
                        width="200"
                        label="Fechas Inicio / Finalización">
                        <template slot-scope="data">
                            {{data.row.startDate | moment(dateFormat)}}
                            &middot;
                            {{data.row.endDate | moment(dateFormat)}}
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Estado"
                        :filters="[{text: 'Publicada', value: 'PUBLISHED'}, {text: 'Inactiva', value: 'INACTIVE'}, {text: 'Borrador', value: 'DRAFT'}]"
                        :filter-method="filterHandler">

                        <template slot-scope="data">
                            <publish-status style="font-size: 12px" :status="data.row.status"/>
                        </template>
                    </el-table-column>

                    <el-table-column align="right" width="100">
                        <template slot="header">
                            <svg-icon
                                style="width: 1.5em; height: 1.5em;"
                                icon-class="reset"
                                class="cursor-pointer"
                                @click="resetFilters"/>
                        </template>

                        <template slot-scope="data">
                            <svg-icon
                                icon-class="use-with-favorite"
                                v-if="data.row.useWithFavorite"
                                style="width: 1.5em; height: 1.5em;"/>
                            <svg-icon
                                icon-class="scope-limit"
                                class="ml-2"
                                v-if="data.row.scopeLimit"
                                style="width: 1.5em; height: 1.5em;"/>
                        </template>
                    </el-table-column>

                </el-table>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {campaignType} from '../../constants/campaign-constants';
    import PublishStatus from "../../components/PublishStatus/index";
    import {dialogs, messages} from "../../constants/text-constants";
    import {activateCampaign, disableCampaign} from "../../api/campaign-api";

    export default {
        name: "CampaignList",
        components: {PublishStatus},
        data() {
            return {
                search: "",
                dateFormat: 'DD/MM/YY'
            }
        },
        created() {
            this.loadCampaigns();
        },
        methods: {
            loadCampaigns() {
                this.$store.dispatch('loadCampaigns');
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            filterByType(value, row) {
                return row.type === value;
            },
            resetFilters() {
                this.$refs.campaignTable.clearFilter();
            },
            goToCampaignDetail(row, _, event) {
                if (event.target.className !== 'el-switch__core') {
                    this.$router.push({ name: 'campaign-profile', params: { uuid: row.uuid} })
                }
            },
            parseType(type) {
                return campaignType[type];
            },
            changeCampaignStatus(campaignId, value) {
                if (value === 'PUBLISHED') {
                    this.activateCampaign(campaignId);
                } else {
                    this.disableCampaign(campaignId);
                }
            },
            activateCampaign(campaignId) {
                this.$confirm(dialogs.campaign.enable.text, {
                    confirmButtonText: dialogs.campaign.enable.confirm,
                    cancelButtonText: dialogs.campaign.enable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.loading = true;
                    activateCampaign(campaignId).then(() => {
                        this.loadCampaigns();
                        this.showMessageChangeCampaignStatusSuccess();
                    });
                }).catch(() => {
                });
            },
            disableCampaign(campaignId) {
                this.$confirm(dialogs.campaign.disable.text, {
                    confirmButtonText: dialogs.campaign.disable.confirm,
                    cancelButtonText: dialogs.campaign.disable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.loading = true;
                    disableCampaign(campaignId).then(() => {
                        this.loadCampaigns();
                        this.showMessageChangeCampaignStatusSuccess();
                    });
                }).catch(() => {
                });
            },
            showMessageChangeCampaignStatusSuccess() {
                this.$message.success(messages.campaign.status.success);
            }
        },
        computed: {
            ...mapGetters([
                'campaigns'
            ]),
            typesFilter() {
                return Object.keys(campaignType).map((key) => {
                    return {
                        value: key,
                        text: campaignType[key]
                    };
                });
            }
        }
    }
</script>
